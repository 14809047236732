import Img from "gatsby-image";
import styled from "styled-components";

const BackgroundImage = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -2;
  min-height: ${props => props.height || "100vh"};
  /* Adjust image positioning (if image covers area with defined height) and add font-family for polyfill */
  & > img {
    object-fit: ${props => props.fit || "cover"} !important;
    object-position: ${props => props.position || "50% 50%"} !important;
    font-family: 'object-fit: ${props =>
      props.fit || "cover"} !important; object-position: ${props =>
  props.position || "50% 50%"} !important;'
      }
`;

export default BackgroundImage;
