import styled from "styled-components";

const Input = styled.input`
  padding: 8px 10px;
  background: ${p => p.theme.colors.greyLight};
  border: none;
  border-radius: 4px;
  box-shadow: ${p => p.theme.shadows.z2};
  width: 100%;
  position: relative;
  font-size: 18px;
  font-family: inherit;
  margin: 10px 0;
  outline: none;

  transition: background 0.15s;
  &:focus {
    background: ${p => p.theme.colors.greyLighter};
  }
`;

export default Input;
